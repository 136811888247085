import React from "react";
import { PageProps } from "gatsby";
import Layout from "../components/Layout";
import { useI18next } from "../utils/i18n";
import { Helmet } from "react-helmet";

type DataProps = unknown;

const NotFoundPage: React.FC<PageProps<DataProps>> = () => {
  const { t } = useI18next();

  return (
    <Layout fullscreen>
      <Helmet meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <div className="constrained flex-1 flex flex-col justify-center items-center text-center">
        <h1 className="mb-12 text-6xl font-bold">{t("404.title")}</h1>
        <p className="text-xl mb-12">{t("404.description")}</p>
        <a href="/" className="btn btn-primary">
          {t("404.button")}
        </a>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
